import { ThemeConfig } from "antd/es/config-provider"

const theme: ThemeConfig = {
  inherit: false,
  components: {
    Form: {
      labelRequiredMarkColor: "#0A1543",
      labelFontSize: 18,
    },
    Menu: {
      padding: 0,
      colorActiveBarHeight: 0,
      fontSize: 14,
      lineHeight: 17,
      colorBgLayout: "transparent",
      colorBgContainer: "transparent",
      itemPaddingInline: 20,
      itemColor: "#FFFFFF",
      itemSelectedColor: "#FFFFFF",
      colorBorder: "transparent",
      colorBorderBg: "transparent",
      colorPrimaryBg: "transparent",
      activeBarBorderWidth: 0,
      activeBarHeight: 0,
      itemHoverColor: "#C2C2C2",
    },
    Select: {
      borderRadius: 8,
      controlHeightLG: 48,
      fontSizeLG: 16,
      colorBorder: "#0A1543",
      colorPrimary: "#0A1543",
      colorPrimaryHover: "#0A1543",
    },
    Layout: {
      footerPadding: 0,
      headerBg: "#0A1543",
      headerHeight: undefined,
      colorBgLayout: "unset",
    },
    Button: {
      colorPrimary: "#0A1543",
      colorPrimaryHover: "#0A1543",
      colorPrimaryActive: "#0A1543",
      borderRadius: 8,
    },
  },
  token: {
    fontSize: 16,
    fontFamily: "Montserrat, sans-serif",
    colorText: "#0A1543",
    colorTextSecondary: "#FFFFFF",
    fontSizeHeading1: 72,
    fontSizeHeading2: 56,
    fontSizeHeading3: 32,
    fontSizeHeading4: 24,
    fontSizeHeading5: 12,
  },
}

export default theme
