import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from "@apollo/client"
import { initMarker } from "@bn-digital/sdk"
import { ConfigProvider } from "antd"
import { createContext, FC, memo, PropsWithChildren, useContext } from "react"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import { useToggle } from "react-use"
import introspection from "../../graphql"
import routes from "../../pages"
import theme from "../../themes"

initMarker({ enabled: true, destination: import.meta.env.VITE_MARKER_ID }).then(() => console.log("Marker initiated."))

type AppProps = {
  burger: { opened: boolean; toggle: VoidFunction }
}

const defaultValue: AppProps = {
  burger: { opened: false, toggle: () => null },
}

const Context = createContext<AppProps>(defaultValue)

type ContextProviderProps = PropsWithChildren

const ContextProvider: FC<ContextProviderProps> = ({ children }) => {
  const [opened, toggle] = useToggle(false)

  return <Context.Provider value={{ burger: { opened, toggle } }}>{children}</Context.Provider>
}

const useApp: () => AppProps = () => useContext(Context)

const client = new ApolloClient({
  link: createHttpLink({
    uri: `${import.meta.env.WEBSITE_API_URL ?? "/graphql"}`,
    credentials: "same-origin",
  }),
  connectToDevTools: import.meta.env.DEV,
  queryDeduplication: true,
  assumeImmutableResults: true,
  cache: new InMemoryCache({
    resultCaching: import.meta.env.PROD,
    possibleTypes: introspection.possibleTypes,
  }),
})

const router = createBrowserRouter(routes)

const App: FC = memo(() => (
  <ApolloProvider client={client}>
    <ContextProvider>
      <ConfigProvider theme={theme}>
        <RouterProvider router={router} />
      </ConfigProvider>
    </ContextProvider>
  </ApolloProvider>
))

export { useApp }
export default App
