import {  Col, Row, Typography } from "antd"
import { FC } from "react"
import { ReactMarkdown,Container } from "../../ui"
import './index.less'


const SafetySection: FC<Partial<TextFragment>> = ({ background,children,heading,visible }) => {
  return visible ? (
    <div className={"safety-section"} style={{background: background ?? "transparent"}}>
      <Row align={"middle"} className={"safety-content"}>
        <Container>
          <Col span={24}>
            {heading && <Typography.Title className={"safety-title"} level={5}>{heading}</Typography.Title>}
          </Col>
          <Col span={24} className={"grid-container"}>
            {children?.map((text,index) => {
              return (
                <div key={index}>
                  {index === 2 ? <hr /> : null}
                  <ReactMarkdown className={"safety-markdown"} content={text?.content ?? ""} />
                </div>
              )
            })}
          </Col>
        </Container>
      </Row>
    </div>
  ) : null
}

export { SafetySection }
