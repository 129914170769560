import { useMediaQuery } from "react-responsive"

const useBreakpoints = () => {
  return {
    isXXLDesktop: useMediaQuery({ maxWidth: 1619 }),
    isDesktop: useMediaQuery({ maxWidth: 1279 }),
    isPreDesktop: useMediaQuery({ maxWidth: 1024 }),
    isTablet: useMediaQuery({ maxWidth: 860 }),
    isPreTablet: useMediaQuery({ maxWidth: 600 }),
    isMobile: useMediaQuery({ maxWidth: 375 }),
  }
}

export { useBreakpoints }
