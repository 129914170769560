import { Layout as BaseLayout,Col,Space } from "antd"
import { FC,Suspense } from "react"
import { Outlet, ScrollRestoration, useLocation } from "react-router-dom"
import { useLayoutQuery } from "../../../graphql"
import { Footer,Header,Loader } from "../index"
import './index.less'

const Layout: FC = () => {
  const { data, loading } = useLayoutQuery()
  const location = useLocation()
  const isForPatients = location.pathname.includes('patients')

  return !loading ? (
    <BaseLayout>
      <ScrollRestoration />
      <BaseLayout.Header>
        <Header forPatients={isForPatients} logo={data?.layout?.data?.attributes?.logo?.data} headerMenu={isForPatients ?  data?.layout?.data?.attributes?.headerForPatients : data?.layout?.data?.attributes?.headerMenu} />
      </BaseLayout.Header>
      <BaseLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </BaseLayout>
      <BaseLayout.Footer>
        <Footer safety={data?.layout?.data?.attributes?.safety} view={data?.layout?.data?.attributes?.footer_styles} footerText={data?.layout?.data?.attributes?.footerText} footerMenu={data?.layout?.data?.attributes?.footerNavigation} social={data?.layout?.data?.attributes?.social} footerLogo={data?.layout?.data?.attributes?.footerLogo?.data} />
      </BaseLayout.Footer>
    </BaseLayout>
  ) : (
    <Space style={{minHeight: '100vh',display: "flex",justifyContent: "center"}} align={"center"}>
      <Col>
        <Loader />
      </Col>
    </Space>
  )
}

export { Layout }
