import { Dispatch, FC, SetStateAction } from "react"

import { NavLink } from "react-router-dom"

import Navigation from "./Navigation"

const HeaderMenu: FC<{
  data: Maybe<Array<Maybe<ComponentUiLinkInput>>>
  setOpenBurger: Dispatch<SetStateAction<boolean>>
}> = ({ data, setOpenBurger }) => (
  <Navigation
    className={"header-menu"}
    mode={"horizontal"}
    disabledOverflow={true}
    defaultActiveFirst
    items={data?.map(item => ({
      key: item?.url ?? "/",
      label: (
        <NavLink to={item?.url ?? "/"} onClick={() => setOpenBurger(false)} target={`_${item?.target}` ?? "_self"}>
          {item?.title ?? ""}
        </NavLink>
      ),
    }))}
  />
)
export { HeaderMenu }
