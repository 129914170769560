import { Button, Col, List, Row, Space, Typography } from "antd"
import { FC, Fragment } from "react"
import Markdown from "react-markdown"
import { Link, NavLink } from "react-router-dom"
import { SafetySection } from "../../section"
import { Container } from "../../ui"
import "./index.less"

interface FooterProps {
  footerMenu?: Maybe<Array<Maybe<GridFragment>>>
  social?: Maybe<GridFragment>
  footerLogo?: Maybe<FileFragment>
  footerText: Maybe<string>
  view?: Maybe<ViewFragment>
  safety?: Maybe<TextFragment>
}

const Footer: FC<FooterProps> = ({ safety, footerText, view, footerLogo, footerMenu, social }) => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <Fragment>
      <SafetySection {...safety} />
      <div
        style={{
          background: `${view?.background || "transparent"} url(${
            view?.media?.data?.attributes?.url || ""
          }) no-repeat center center / cover`,
        }}
      >
        <Container className={"footer-container"}>
          <Row>
            <Col span={24}>
              <NavLink to={"/"}>
                {footerLogo?.attributes && (
                  <img src={footerLogo.attributes.url} alt={footerLogo.attributes.alternativeText ?? ""} />
                )}
              </NavLink>
            </Col>
            <Row className={"footer-navigation"}>
              <Col className={"navigation-lists"} lg={{ span:16,offset: 0 }} md={{ span:24,offset: 0 }}>
                {footerMenu &&
                  footerMenu.map(item => {
                    return item?.visible ? (
                      <Space key={item?.id} className={"footer-list"} direction={"vertical"}>
                        <Typography.Title level={4}>{item?.title}</Typography.Title>
                        {item && (
                          <List
                            dataSource={item.children}
                            className={"navigation-list"}
                            renderItem={item => {
                              return (
                                <List.Item>
                                  {item?.value !== "none" && item?.value ? <NavLink to={item?.value ?? ""}>{item?.content}</NavLink> : <a download target={"_blank"} href={item?.image?.data?.attributes?.url ?? ""} rel={"noreferrer"}>{item?.content}</a>}
                                </List.Item>
                              )
                            }}
                          />
                        )}
                      </Space>
                    ) : null
                  })}
              </Col>
              <Col lg={{offset: 1,span: 7}} md={{offset: 0,span: 24}}>
                {social?.visible ? (
                  <Space direction={"vertical"}>
                    <Typography.Title level={4}>{social?.title}</Typography.Title>
                    {social && (
                      <List
                        className={"social-list"}
                        grid={{
                          gutter: 16,
                        }}
                        dataSource={social.children}
                        renderItem={item => {
                          return (
                            item?.image?.data?.attributes && (
                              <List.Item>
                                <NavLink target={"_blank"} to={item?.value} rel={"noreferrer"}>
                                  <img
                                    src={item.image.data.attributes.url ?? ""}
                                    alt={item.image.data.attributes.alternativeText ?? ""}
                                  />
                                </NavLink>
                              </List.Item>
                            )
                          )
                        }}
                      />
                    )}
                    <Button className={"secondary footer-btn"} type={"default"}>
                      <Link to={'/patients/find-provider'}>
                        Find a Provider
                      </Link>
                    </Button>
                  </Space>
                ) : null}
              </Col>
            </Row>
          </Row>
          <hr />
          <Markdown className={"footer-bottom"}>{footerText ?? ""}</Markdown>
        </Container>
      </div>
    </Fragment>
  )
}

export { Footer }
