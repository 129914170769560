import { FC, lazy } from "react"
import { RouteObject } from "react-router-dom"

import DefaultLayout, { Loader } from "../components/layout"

const Home = lazy<FC>(() => import("./home"))
const DynamicPage = lazy<FC>(() => import("./dynamic"))
const ContactUsBusiness = lazy<FC>(() => import("./contact-business"))
const ContactUsClients = lazy<FC>(() => import("./contact-clients"))
const NotFound = lazy<FC>(() => import("./not-found"))
const FindProvider = lazy<FC>(() => import("./find-provider"))

const routes: RouteObject[] = [
  {
    Component: DefaultLayout,
    loader: () => <Loader spinning />,
    children: [
      {
        Component: Home,
        path: "",
        index: true,
      },
      {
        Component: Home,
        path: "physicians",
        index: true,
      },
      {
        Component: Home,
        path: "physicians/page/home",
        index: true,
      },
      {
        Component: DynamicPage,
        path: "physicians/page/:pathname",
      },
      {
        Component: ContactUsBusiness,
        path: "physicians/contact-us",
      },
      {
        Component: DynamicPage,
        path: "patients/page/:pathname",
      },
      {
        Component: DynamicPage,
        path: "patients/page/home",
        index: true,
      },
      {
        Component: FindProvider,
        path: "patients/find-provider",
      },
      {
        Component: ContactUsClients,
        path: "patients/contact-us",
      },
      {
        Component: NotFound,
        path: "*",
      },
      {
        Component: NotFound,
        path: "patients/not-found",
      },
      {
        Component: NotFound,
        path: "physicians/not-found",
      },
    ],
  },
]

export default routes
