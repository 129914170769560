import { Button, Col, Drawer, Space, Typography } from "antd"
import { FC, Fragment, useEffect, useState } from "react"
import { NavLink, useLocation } from "react-router-dom"
import { useBreakpoints } from "src/helpers/useBreakpoints"
import { Container, HeaderMenu, SvgIcon } from "../../ui"
import "./index.less"

interface HeaderProps {
  logo?: Maybe<FileFragment>
  headerMenu?: Maybe<Array<Maybe<ComponentUiLink>>>
  forPatients?: boolean
}

const Header: FC<HeaderProps> = ({ forPatients,headerMenu, logo }) => {
  const { isXXLDesktop, isTablet } = useBreakpoints()
  const [openBurger, setOpenBurger] = useState(false)
  const {pathname} = useLocation()
  const isPatientsHero = pathname.includes("patients/page/home") || pathname.includes("patients/page/how-it-works/")

  if (openBurger) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "";
  }

  useEffect(() => {
    const header = document.querySelector("header")
    if (isPatientsHero) {
      if (header) {
        header.classList.add("patients-header")
      }
    } else {
      if (header) {
        header.classList.remove("patients-header")
      }
    }

  }, [pathname, isPatientsHero])

  const headerText =
    <Typography.Text type={"secondary"} className={"global-navigation-links"}>
    {!forPatients ? "This site is intended for U.S. healthcare professionals only." : `This Site Is Intended For U.S. Residents Only ` + ` | `}{forPatients && <NavLink to={"physicians/page/home"}>{"For Healthcare Professionals"}</NavLink>}
</Typography.Text>
  return (
    <Container className={"header-container"}>
      <div className={"aside-container"}>
        {isXXLDesktop && (
          <NavLink className={"logo-container"} to={`${forPatients ? "patients/page/home/" : "/"}`} style={{ display: "flex" }}>
            {logo?.attributes && (
              <img className={"header-logo"} src={logo.attributes.url} alt={logo.attributes.alternativeText ?? ""} />
            )}
          </NavLink>
        )}
        {isTablet ? (
          <Fragment>
            <Button
              className={"burger-btn"}
              onClick={() => setOpenBurger(prev => !prev)}
              icon={<SvgIcon type={"burger"} />}
            />
            <Drawer
              contentWrapperStyle={{ height: "auto" }}
              rootClassName={`burger-drawer ${openBurger ? "open" : ""}`}
              width={"100%"}
              placement={"top"}
              open={openBurger}
            >
              {headerMenu && <HeaderMenu setOpenBurger={setOpenBurger} data={headerMenu} />}
              <NavLink to={forPatients ? "patients/contact-us" : "patients/find-provider"}>
                <Button type={"default"} className={"request-btn"} onClick={() => setOpenBurger(prev => !prev)}>
                  {forPatients ? "Request Information" : "Find a provider"}
                </Button>
              </NavLink>
              <Space size={4} direction={"vertical"} className={"aside-content"}>
                <Col className={"global-navigation-links"}>
                  <NavLink to={"patients/page/home/"}>Consumer Site</NavLink>
                  <span> | </span>
                  <NavLink to={"/"}>Provider Portal</NavLink>
                </Col>
                <Typography.Text type={"secondary"}>
                  {headerText}
                </Typography.Text>
              </Space>
            </Drawer>
          </Fragment>
        ) : (
          <Space size={4} direction={"vertical"} className={"aside-content"}>
            {!forPatients && <Col className={"global-navigation-links"}>
              <NavLink to={"patients/page/home/"}>Consumer Site</NavLink>
              <span> | </span>
              <NavLink to={"/"}>Provider Portal</NavLink>
            </Col>}

            <Typography.Text type={"secondary"}>
              {headerText}
            </Typography.Text>
          </Space>
        )}
      </div>

      {!isTablet && (
        <div className={"header-content"}>
          {!isXXLDesktop && (
            <NavLink to={"/"}>
              {logo?.attributes && <img className={"header-logo"} src={logo.attributes.url} alt={logo.attributes.alternativeText ?? ""} />}
            </NavLink>
          )}
          {headerMenu && <HeaderMenu setOpenBurger={setOpenBurger} data={headerMenu} />}
          <NavLink to={forPatients ? "patients/contact-us" : "patients/find-provider"}>
            <Button type={"default"} className={"request-btn"}>
              {forPatients ? "Request Information" : "Find A Provider"}
            </Button>
          </NavLink>
        </div>
      )}
    </Container>
  )
}

export { Header }
