import { FC, SVGProps } from "react"

import { ReactComponent as LinkIcon } from "./images/arrow-link.svg"
import { ReactComponent as ArrowIcon } from "./images/arrow.svg"
import { ReactComponent as BurgerIcon } from "./images/burger.svg"
import { ReactComponent as CollapseIcon } from "./images/collapsed-icon.svg"
import { ReactComponent as CrossIcon } from "./images/cross.svg"
import { ReactComponent as ExpandIcon } from "./images/expanded-icon.svg"

export type IconTypes = "expand" | "collapse" | "burger" | "cross" | "arrow" | "link" | string

const icons: {
  [key: string]: FC<SVGProps<SVGSVGElement>>
} = {
  burger: BurgerIcon,
  expand: ExpandIcon,
  collapse: CollapseIcon,
  arrow: ArrowIcon,
  link: LinkIcon,
  cross: CrossIcon,
} as const

type SvgIconProps = SVGProps<SVGSVGElement> & { type: IconTypes }

const SvgIcon: FC<SvgIconProps> = ({ type, ...svgProps }) => {
  const Icon = icons[type] ?? null
  return Icon && <Icon {...svgProps} />
}

export { SvgIcon }
