import { CSSProperties, FC } from "react"
import Markdown from "react-markdown"
import "./index.less"

interface ReactMarkdownProps {
  className: string
  content: string
  text_color?: string
  paragraph_styles?: CSSProperties
}

const ReactMarkdown: FC<ReactMarkdownProps> = ({ className, paragraph_styles, content }) => {
  return (
    <Markdown
      components={{
        p: ({ node, ...props }) => <p style={{ ...paragraph_styles }} {...props} />,
      }}
      className={`markdown ${className}`}
    >
      {content}
    </Markdown>
  )
}

export { ReactMarkdown }
