import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

      export type PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "ComponentDataCard",
      "ComponentDataEntry",
      "ComponentDataPanel",
      "ComponentDataProviderLink",
      "ComponentDataView",
      "ComponentUiAbstraction",
      "ComponentUiAccordion",
      "ComponentUiBar",
      "ComponentUiBlock",
      "ComponentUiBook",
      "ComponentUiCaption",
      "ComponentUiCaptionedGrid",
      "ComponentUiCards",
      "ComponentUiCarousel",
      "ComponentUiCollage",
      "ComponentUiGrid",
      "ComponentUiHeadline",
      "ComponentUiLink",
      "ComponentUiParagraph",
      "ComponentUiQuestions",
      "ComponentUiSection",
      "ComponentUiSegment",
      "ComponentUiText",
      "ContactBusiness",
      "ContactClient",
      "Layout",
      "Page",
      "Provider",
      "ReactIconsIconlibrary",
      "Slide",
      "Story",
      "Testimonial",
      "UploadFile",
      "UploadFolder",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser"
    ],
    "PageContentDynamicZone": [
      "ComponentUiAbstraction",
      "ComponentUiAccordion",
      "ComponentUiBar",
      "ComponentUiBlock",
      "ComponentUiBook",
      "ComponentUiCaption",
      "ComponentUiCaptionedGrid",
      "ComponentUiCards",
      "ComponentUiCarousel",
      "ComponentUiCollage",
      "ComponentUiParagraph",
      "ComponentUiQuestions",
      "ComponentUiSection",
      "ComponentUiSegment",
      "ComponentUiText",
      "Error"
    ]
  }
};
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "ComponentDataCard",
      "ComponentDataEntry",
      "ComponentDataPanel",
      "ComponentDataProviderLink",
      "ComponentDataView",
      "ComponentUiAbstraction",
      "ComponentUiAccordion",
      "ComponentUiBar",
      "ComponentUiBlock",
      "ComponentUiBook",
      "ComponentUiCaption",
      "ComponentUiCaptionedGrid",
      "ComponentUiCards",
      "ComponentUiCarousel",
      "ComponentUiCollage",
      "ComponentUiGrid",
      "ComponentUiHeadline",
      "ComponentUiLink",
      "ComponentUiParagraph",
      "ComponentUiQuestions",
      "ComponentUiSection",
      "ComponentUiSegment",
      "ComponentUiText",
      "ContactBusiness",
      "ContactClient",
      "Layout",
      "Page",
      "Provider",
      "ReactIconsIconlibrary",
      "Slide",
      "Story",
      "Testimonial",
      "UploadFile",
      "UploadFolder",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser"
    ],
    "PageContentDynamicZone": [
      "ComponentUiAbstraction",
      "ComponentUiAccordion",
      "ComponentUiBar",
      "ComponentUiBlock",
      "ComponentUiBook",
      "ComponentUiCaption",
      "ComponentUiCaptionedGrid",
      "ComponentUiCards",
      "ComponentUiCarousel",
      "ComponentUiCollage",
      "ComponentUiParagraph",
      "ComponentUiQuestions",
      "ComponentUiSection",
      "ComponentUiSegment",
      "ComponentUiText",
      "Error"
    ]
  }
};
      export default result;
    
export const ContactBusinessFragmentDoc = gql`
    fragment ContactBusiness on ContactBusinessEntity {
  id
  attributes {
    title
    product
    state
    action
    city
    comments
    company
    country
    first_name
    email
    occupation
    phone
    speciality
    zip
    last_name
  }
}
    `;
export const ContactClientFragmentDoc = gql`
    fragment ContactClient on ContactClientEntity {
  id
  attributes {
    state
    city
    comments
    country
    first_name
    email
    phone
    zip
    last_name
  }
}
    `;
export const FileFragmentDoc = gql`
    fragment File on UploadFileEntity {
  id
  attributes {
    previewUrl
    alternativeText
    url
  }
}
    `;
export const EntryFragmentDoc = gql`
    fragment Entry on ComponentDataEntry {
  id
  image {
    data {
      ...File
    }
  }
  content
  value
}
    `;
export const GridFragmentDoc = gql`
    fragment Grid on ComponentUiGrid {
  visible
  id
  title
  children {
    ...Entry
  }
}
    `;
export const HeadlineFragmentDoc = gql`
    fragment Headline on ComponentUiHeadline {
  title
  underlined
  __typename
}
    `;
export const ViewFragmentDoc = gql`
    fragment View on ComponentDataView {
  id
  media {
    data {
      ...File
    }
  }
  background
  __typename
}
    `;
export const LinkFragmentDoc = gql`
    fragment Link on ComponentUiLink {
  id
  title
  url
  target
}
    `;
export const SectionFragmentDoc = gql`
    fragment Section on ComponentUiSection {
  __typename
  layout
  cover_image {
    data {
      ...File
    }
  }
  id
  visible
  content
  headline {
    ...Headline
  }
  view {
    ...View
  }
  button {
    ...Link
  }
}
    `;
export const ParagraphFragmentDoc = gql`
    fragment Paragraph on ComponentUiParagraph {
  id
  title
  content
  underlined
}
    `;
export const CardFragmentDoc = gql`
    fragment Card on ComponentDataCard {
  id
  title
  text
  img {
    data {
      ...File
    }
  }
}
    `;
export const CaptionedGridFragmentDoc = gql`
    fragment CaptionedGrid on ComponentUiCaptionedGrid {
  id
  __typename
  texture {
    data {
      ...File
    }
  }
  top {
    ...Headline
  }
  desc
  elements {
    ...Card
  }
  button {
    ...Link
  }
  cards_color
  background
}
    `;
export const CardsFragmentDoc = gql`
    fragment Cards on ComponentUiCards {
  id
  __typename
  items {
    ...Card
  }
  cardBackground
  sectionBackground
  button {
    ...Link
  }
  texture {
    data {
      ...File
    }
  }
  __typename
}
    `;
export const StoryFragmentDoc = gql`
    fragment Story on StoryEntity {
  id
  __typename
  attributes {
    name {
      title
      underlined
    }
    content
    sideline
    image_side
    snapshot {
      data {
        ...File
      }
    }
  }
}
    `;
export const BookFragmentDoc = gql`
    fragment Book on ComponentUiBook {
  backdrop
  __typename
  stories {
    data {
      ...Story
    }
  }
}
    `;
export const BarFragmentDoc = gql`
    fragment Bar on ComponentUiBar {
  id
  title
  texture {
    data {
      ...File
    }
  }
  accent
  subtitle
  styles {
    ...View
  }
  __typename
}
    `;
export const BlockFragmentDoc = gql`
    fragment Block on ComponentUiBlock {
  content
  header {
    ...Headline
  }
  content_color
  content_align
  block_background
  title_color
  title_inside
  __typename
}
    `;
export const SlideFragmentDoc = gql`
    fragment Slide on SlideEntity {
  id
  attributes {
    heading
    image_side
    image {
      data {
        ...File
      }
    }
    content
    button {
      ...Link
    }
    text_color
  }
}
    `;
export const CarouselFragmentDoc = gql`
    fragment Carousel on ComponentUiCarousel {
  id
  styles {
    ...View
  }
  __typename
  slides {
    data {
      ...Slide
    }
  }
}
    `;
export const AbstractionFragmentDoc = gql`
    fragment Abstraction on ComponentUiAbstraction {
  main
  view {
    ...View
  }
  head {
    ...Entry
  }
  cards {
    ...Entry
  }
  __typename
}
    `;
export const SegmentFragmentDoc = gql`
    fragment Segment on ComponentUiSegment {
  __typename
  theme
  image_side
  head {
    ...Headline
  }
  content
  button {
    ...Link
  }
  view {
    ...View
  }
  texture {
    data {
      ...File
    }
  }
}
    `;
export const PanelFragmentDoc = gql`
    fragment Panel on ComponentDataPanel {
  heading
  id
  content
}
    `;
export const QuestionsFragmentDoc = gql`
    fragment Questions on ComponentUiQuestions {
  __typename
  id
  headline {
    ...Headline
  }
  subtitle
  elements {
    ...Panel
  }
  view {
    ...View
  }
}
    `;
export const TextFragmentDoc = gql`
    fragment Text on ComponentUiText {
  id
  visible
  heading
  background
  children {
    ...Paragraph
  }
}
    `;
export const CaptionFragmentDoc = gql`
    fragment Caption on ComponentUiCaption {
  __typename
  id
  underlined
  visible
  description
  heading
  button {
    ...Link
  }
  img_side
  text_align
  img {
    data {
      ...File
    }
  }
}
    `;
export const TestimonialFragmentDoc = gql`
    fragment Testimonial on TestimonialEntity {
  id
  attributes {
    iframe_link
    link
    place
    full_spec
    phrase
    media {
      data {
        ...File
      }
    }
    title
  }
}
    `;
export const CollageFragmentDoc = gql`
    fragment Collage on ComponentUiCollage {
  button {
    ...Link
  }
  type
  subtitle
  headline {
    ...Headline
  }
  view {
    ...View
  }
  testimonials {
    data {
      ...Testimonial
    }
  }
  __typename
}
    `;
export const AccordionFragmentDoc = gql`
    fragment Accordion on ComponentUiAccordion {
  title
  view {
    ...View
  }
  __typename
  panels {
    ...Panel
  }
}
    `;
export const PageFragmentDoc = gql`
    fragment Page on Page {
  __typename
  hero {
    ...Section
  }
  texture {
    data {
      ...File
    }
  }
  content {
    ...Paragraph
    ...CaptionedGrid
    ...Cards
    ...Book
    ...Bar
    ...Block
    ...Carousel
    ...Abstraction
    ...Segment
    ...Questions
    ...Text
    ...Caption
    ...Section
    ...Collage
    ...Accordion
  }
}
    `;
export const ProviderLinkFragmentDoc = gql`
    fragment ProviderLink on ComponentDataProviderLink {
  icon {
    data {
      ...File
    }
  }
  name
  link
}
    `;
export const ProviderFragmentDoc = gql`
    fragment Provider on ProviderEntity {
  id
  attributes {
    avatar {
      data {
        ...File
      }
    }
    fullName
    position
    workplace
    address
    phone
    links {
      ...ProviderLink
    }
  }
}
    `;
export const ContactBusinessDocument = gql`
    mutation contactBusiness($input: ContactBusinessInput!) {
  createContactBusiness(data: $input) {
    data {
      ...ContactBusiness
    }
  }
}
    ${ContactBusinessFragmentDoc}`;
export type ContactBusinessMutationFn = Apollo.MutationFunction<ContactBusinessMutation, ContactBusinessMutationVariables>;
export function useContactBusinessMutation(baseOptions?: Apollo.MutationHookOptions<ContactBusinessMutation, ContactBusinessMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ContactBusinessMutation, ContactBusinessMutationVariables>(ContactBusinessDocument, options);
      }
export type ContactBusinessMutationHookResult = ReturnType<typeof useContactBusinessMutation>;
export type ContactBusinessMutationResult = Apollo.MutationResult<ContactBusinessMutation>;
export const ContactClientDocument = gql`
    mutation contactClient($input: ContactClientInput!) {
  createContactClient(data: $input) {
    data {
      ...ContactClient
    }
  }
}
    ${ContactClientFragmentDoc}`;
export type ContactClientMutationFn = Apollo.MutationFunction<ContactClientMutation, ContactClientMutationVariables>;
export function useContactClientMutation(baseOptions?: Apollo.MutationHookOptions<ContactClientMutation, ContactClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ContactClientMutation, ContactClientMutationVariables>(ContactClientDocument, options);
      }
export type ContactClientMutationHookResult = ReturnType<typeof useContactClientMutation>;
export type ContactClientMutationResult = Apollo.MutationResult<ContactClientMutation>;
export const LayoutDocument = gql`
    query layout {
  layout {
    data {
      attributes {
        footerNavigation {
          ...Grid
        }
        headerForPatients {
          ...Link
        }
        footer_styles {
          ...View
        }
        footerText
        social {
          ...Grid
        }
        headerMenu {
          ...Link
        }
        safety {
          ...Text
        }
        footerLogo {
          data {
            ...File
          }
        }
        logo {
          data {
            ...File
          }
        }
      }
    }
  }
}
    ${GridFragmentDoc}
${EntryFragmentDoc}
${FileFragmentDoc}
${LinkFragmentDoc}
${ViewFragmentDoc}
${TextFragmentDoc}
${ParagraphFragmentDoc}`;
export function useLayoutQuery(baseOptions?: Apollo.QueryHookOptions<LayoutQuery, LayoutQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LayoutQuery, LayoutQueryVariables>(LayoutDocument, options);
      }
export function useLayoutLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LayoutQuery, LayoutQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LayoutQuery, LayoutQueryVariables>(LayoutDocument, options);
        }
export type LayoutQueryHookResult = ReturnType<typeof useLayoutQuery>;
export type LayoutLazyQueryHookResult = ReturnType<typeof useLayoutLazyQuery>;
export type LayoutQueryResult = Apollo.QueryResult<LayoutQuery, LayoutQueryVariables>;
export const MeDocument = gql`
    query me {
  me {
    id
  }
}
    `;
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const PageDocument = gql`
    query page($key: StringFilterInput!) {
  pages(filters: {pathname: $key}) {
    data {
      attributes {
        ...Page
      }
    }
  }
}
    ${PageFragmentDoc}
${SectionFragmentDoc}
${FileFragmentDoc}
${HeadlineFragmentDoc}
${ViewFragmentDoc}
${LinkFragmentDoc}
${ParagraphFragmentDoc}
${CaptionedGridFragmentDoc}
${CardFragmentDoc}
${CardsFragmentDoc}
${BookFragmentDoc}
${StoryFragmentDoc}
${BarFragmentDoc}
${BlockFragmentDoc}
${CarouselFragmentDoc}
${SlideFragmentDoc}
${AbstractionFragmentDoc}
${EntryFragmentDoc}
${SegmentFragmentDoc}
${QuestionsFragmentDoc}
${PanelFragmentDoc}
${TextFragmentDoc}
${CaptionFragmentDoc}
${CollageFragmentDoc}
${TestimonialFragmentDoc}
${AccordionFragmentDoc}`;
export function usePageQuery(baseOptions: Apollo.QueryHookOptions<PageQuery, PageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PageQuery, PageQueryVariables>(PageDocument, options);
      }
export function usePageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PageQuery, PageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PageQuery, PageQueryVariables>(PageDocument, options);
        }
export type PageQueryHookResult = ReturnType<typeof usePageQuery>;
export type PageLazyQueryHookResult = ReturnType<typeof usePageLazyQuery>;
export type PageQueryResult = Apollo.QueryResult<PageQuery, PageQueryVariables>;
export const ProvidersDocument = gql`
    query providers {
  providers(pagination: {limit: 100}) {
    data {
      ...Provider
    }
  }
}
    ${ProviderFragmentDoc}
${FileFragmentDoc}
${ProviderLinkFragmentDoc}`;
export function useProvidersQuery(baseOptions?: Apollo.QueryHookOptions<ProvidersQuery, ProvidersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProvidersQuery, ProvidersQueryVariables>(ProvidersDocument, options);
      }
export function useProvidersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProvidersQuery, ProvidersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProvidersQuery, ProvidersQueryVariables>(ProvidersDocument, options);
        }
export type ProvidersQueryHookResult = ReturnType<typeof useProvidersQuery>;
export type ProvidersLazyQueryHookResult = ReturnType<typeof useProvidersLazyQuery>;
export type ProvidersQueryResult = Apollo.QueryResult<ProvidersQuery, ProvidersQueryVariables>;